import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import { APPEND_RECORD, FETCHING_RECORDS } from "./accounting.actions.type";
import { GET_RECORDS } from "./accounting.getters.type";
import { SET_RECORD, SET_RECORDS } from "./accounting.mutations.type";

const service = () => container.api.accounting;

const state = {
  records: [],
};

const actions = {
  async [FETCHING_RECORDS]({ commit, dispatch }, customerId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const accounting = await dispatch(
      EXECUTE_REQUEST,
      service().records(customerId)
    );
    commit(SET_RECORDS, accounting);
  },

  async [APPEND_RECORD]({ commit, dispatch }, payload) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, service().appendRecord(payload));
    commit(SET_RECORD, payload.record);
  },
};

const getters = {
  [GET_RECORDS]: (state) => state.records,
};

const mutations = {
  [SET_RECORD](state, record) {
    state.records = [record, ...state.records];
  },
  [SET_RECORDS](state, records) {
    state.records = records;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
