import { TRIAL_PROCESSING_TIME, TRIAL_STORAGE } from "../config";
import { storageTransformer } from "../service/transformer/storageTransformer";
import { dateTransformer } from "../service/utils";

export default class Wage {
  constructor(totalTime, timeConsumed, totalStorage, storageUsed) {
    this.totalTime = totalTime;
    this.timeConsumed = timeConsumed;
    this.totalStorage = totalStorage;
    this.storageUsed = storageUsed;
  }

  static demo() {
    return new Wage(TRIAL_PROCESSING_TIME, 0, TRIAL_STORAGE, 0);
  }

  stringyTime() {
    return `${dateTransformer.secondsToMinutes(
      this.timeConsumed
    )} / ${dateTransformer.secondsToMinutes(this.totalTime)} min`;
  }

  stringyStorage() {
    return `${storageTransformer.humanReadable(
      this.storageUsed
    )} / ${storageTransformer.humanReadable(this.totalStorage)}`;
  }
}
