const byteSizes = ["Bytes", "KB", "MB", "GB", "TB"];

export const storageTransformer = {
  humanReadable(bytes) {
    if (bytes === 0) {
      return "0 Byte";
    }

    const absValue = Math.abs(parseInt(bytes));
    const i = Math.floor(Math.log(absValue) / Math.log(1024));
    const output = `${Math.round(absValue / Math.pow(1024, i))} ${
      byteSizes[i]
    }`;

    return bytes > 0 ? output : `-${output}`;
  },

  byteToGiga(bytes) {
    bytes = parseInt(bytes);
    if (bytes > 0) {
      return parseInt(Math.floor(bytes / (1024 * 1024 * 1024)));
    }
    return 0;
  },

  gigaToBytes(bytes) {
    if (bytes > 0) {
      return parseInt(bytes * (1024 * 1024 * 1024));
    }
    return 0;
  },
};
