import qs from "qs";

import { GENERIC_CODE_ERROR } from "../config";

export default {
  init(endPoint) {
    this.endPoint = endPoint;
    this.token = "";
  },

  changeToken(aToken) {
    this.token = aToken;
  },

  async get(path) {
    const request = fetch(this.getUrl(path), {
      method: "GET",
      headers: this.getHeaders(),
    });
    return this.process(request);
  },

  async post(path, data) {
    const request = fetch(this.getUrl(path), {
      method: "POST",
      body: qs.stringify(data),
      headers: this.getHeaders(),
    });
    return this.process(request);
  },

  async put(path, data) {
    const request = fetch(this.getUrl(path), {
      method: "PUT",
      body: qs.stringify(data),
      headers: this.getHeaders(),
    });
    return this.process(request);
  },

  async delete(path) {
    const request = fetch(this.getUrl(path), {
      method: "DELETE",
      headers: this.getHeaders(),
    });
    return this.process(request);
  },

  async process(request) {
    const response = await request;
    const json = await response.json();
    if (json.success === false) {
      throw new Error(json.error_code ?? GENERIC_CODE_ERROR);
    }
    return json;
  },

  getUrl(path) {
    return this.endPoint + path;
  },

  getHeaders() {
    if (this.token === "") {
      return {
        "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
      };
    }
    return {
      Authorization: `Bearer ${this.token}`,
      "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
    };
  },
};
