import { ulid } from "ulid";

import { DEFAULT_LOCALE, TRIAL_EXPIRE_DATE } from "../config";
import Contact from "./Contact";
import Wage from "./Wage";

export default class Customer {
  constructor(
    id,
    company,
    contact,
    wage,
    clients,
    createdAt,
    expiredAt,
    locale
  ) {
    this.id = id;
    this.company = company;
    this.contact = contact;
    this.wage = wage;
    this.clients = clients;
    this.createdAt = createdAt;
    this.expires = expiredAt;
    this.locale = locale;
  }

  static empty() {
    return new Customer(
      ulid(),
      "",
      Contact.empty(),
      Wage.demo(),
      1,
      new Date(),
      TRIAL_EXPIRE_DATE,
      DEFAULT_LOCALE
    );
  }
}
