import Cookies from "js-cookie";

import Token from "../models/Token";

const REFRESH_TOKEN = "refresh_token";
const REFRESH_EXPIRATION = "refresh_exp";

export default {
  saveRefreshToken(refreshToken) {
    const refresh = this.jwtToProfile(refreshToken);
    Cookies.set(REFRESH_TOKEN, refreshToken);
    Cookies.set(REFRESH_EXPIRATION, refresh.exp);
  },

  jwtToProfile(jwt) {
    try {
      return JSON.parse(atob(jwt.split(".")[1]));
    } catch (e) {
      return new Error("Cannot parse the JWT token");
    }
  },

  getRefreshToken() {
    return new Token(
      Cookies.get(REFRESH_TOKEN),
      Cookies.get(REFRESH_EXPIRATION)
    );
  },

  clear() {
    Cookies.remove(REFRESH_TOKEN);
    Cookies.remove(REFRESH_EXPIRATION);
  },
};
