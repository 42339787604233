import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import { FETCHING_CUSTOMERS, REGISTER_CUSTOMER } from "./customer.actions.type";
import { GET_CUSTOMER_BY_ID, GET_CUSTOMERS } from "./customer.getters.type";
import { SET_CUSTOMER, SET_CUSTOMERS } from "./customer.mutations.type";

const service = () => container.api.customers;

const state = {
  customers: [],
};

const actions = {
  async [FETCHING_CUSTOMERS]({ commit, dispatch }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const customers = await dispatch(EXECUTE_REQUEST, service().customers());
    commit(SET_CUSTOMERS, customers);
  },

  async [REGISTER_CUSTOMER]({ commit, dispatch }, customer) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, service().register(customer));
    commit(SET_CUSTOMER, customer);
  },
};

const getters = {
  [GET_CUSTOMERS]: (state) => state.customers,

  [GET_CUSTOMER_BY_ID]: (state) => (customerId) => {
    const customer = state.customers.filter(
      (customer) => customer.id === customerId
    );
    return customer.length === 0 ? null : customer[0];
  },
};

const mutations = {
  [SET_CUSTOMER](state, customer) {
    state.customers = [...state.customers, customer];
  },
  [SET_CUSTOMERS](state, customers) {
    state.customers = customers;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
