import { API_BACKEND_URL, IS_DEBUG } from "./config";
import accounting from "./service/accounting.service";
import ApiService from "./service/api.service";
import auth from "./service/auth.service";
import customer from "./service/customer.service";
import session from "./service/session.service";

const debug = IS_DEBUG;
ApiService.init(API_BACKEND_URL);

export const container = {
  api: {
    auth: auth(ApiService),
    accounting: accounting(ApiService),
    customers: customer(ApiService),
  },
  session,
  httpRequester: ApiService,
  debug,
};
