import { createRouter, createWebHistory } from "vue-router";

import { Role } from "../models/Role";
import { guardPanelRouter } from "./guardPanelRouter";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/activation/:username/:token",
    name: "activation",
    component: () => import("../views/Activation"),
  },
  {
    path: "/panel",
    name: "panel",
    beforeEnter: guardPanelRouter,
    component: () => import("../views/panel/Layout.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/panel/EmptySection.vue"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/panel/Dashboard.vue"),
      },
      {
        path: "customer-new",
        name: "customer-new",
        meta: { middleware: Role.admin },
        component: () => import("../views/panel/CustomerNew.vue"),
      },
      {
        path: "customers",
        name: "customers",
        component: () => import("../views/panel/CustomerList.vue"),
      },
      {
        path: "customers/:customerId",
        name: "accounting",
        component: () => import("../views/panel/CustomerAccounting.vue"),
      },
      {
        path: ":patchMatch(.*)",
        component: () => import("../views/panel/EmptySection.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "login",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
