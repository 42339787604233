import Record from "../models/Record";
import { dateTransformer } from "./utils";

export default function (client) {
  async function records(customerId) {
    const { data } = await client.get(`/accounting/${customerId}`);
    return data.accounting.records.map(
      (record) =>
        new Record(
          record.id,
          record.description,
          record.time,
          record.storage,
          dateTransformer.toDate(record.created_at),
          dateTransformer.toDate(record.expires_at)
        )
    );
  }

  async function appendRecord({ accounting, record }) {
    await client.post(`/accounting/${accounting}/record`, {
      id: record.id,
      description: record.description,
      time: record.increaseTime,
      storage: record.maxStorage,
      expires: dateTransformer.toISOString(record.expires),
    });
  }

  return {
    records,
    appendRecord,
  };
}
