import { ulid } from "ulid";

import {
  TRIAL_EXPIRE_DATE,
  TRIAL_PROCESSING_TIME,
  TRIAL_STORAGE,
} from "../config";

export default class Record {
  constructor(id, description, increaseTime, maxStorage, createdAt, expiresAt) {
    this.id = id;
    this.description = description;
    this.increaseTime = increaseTime;
    this.maxStorage = maxStorage;
    this.createdAt = createdAt;
    this.expires = expiresAt;
  }

  static empty() {
    return new Record(
      ulid(),
      "",
      TRIAL_PROCESSING_TIME,
      TRIAL_STORAGE,
      new Date(),
      TRIAL_EXPIRE_DATE
    );
  }
}
