import { FORBIDDEN_MSG_ERROR } from "../config";
import Profile from "../models/Profile";
import Token from "../models/Token";
import { VALID_AUTH_SCOPE } from "./utils";

export const parseData = {
  jwt(token) {
    return JSON.parse(atob(token.split(".")[1]));
  },

  profile(token) {
    const scope = token.scope.filter((data) => data.scope === VALID_AUTH_SCOPE);
    if (scope.length === 0) {
      throw new Error(FORBIDDEN_MSG_ERROR);
    }
    return new Profile(
      token.sub,
      token.name,
      token.surname,
      token.email,
      scope[0].roles ?? []
    );
  },

  token(jwtRaw, tokenExp) {
    return new Token(jwtRaw, new Date(tokenExp * 1000));
  },
};
