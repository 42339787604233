import dayjs from "dayjs";

import { GENERIC_MSG_ERROR } from "../config";
import { parseData } from "./parse.service";

export const VALID_AUTH_SCOPE = "BackOffice";

export const dateTransformer = {
  toDate(date) {
    return new Date(date);
  },

  toString(date) {
    if (date instanceof Date) {
      return dayjs(date).format("DD/MM/YYYY");
    }
    throw new Error("Date cannot be transformed to string");
  },

  toISOString(date) {
    if (date instanceof Date) {
      return date.toISOString();
    }
    return "";
  },

  secondsToMinutes(seconds) {
    return Math.ceil(seconds / 60);
  },
};

export function extractJwtData(JWToken) {
  let token;
  try {
    token = parseData.jwt(JWToken);
  } catch (e) {
    return new Error(GENERIC_MSG_ERROR);
  }
  return {
    profile: parseData.profile(token),
    token: parseData.token(JWToken, token.exp),
  };
}
