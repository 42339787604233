export default function (client) {
  async function activation(activate) {
    return await client.post("/auth/activate", {
      username: activate.username,
      password: activate.password,
      token: activate.token,
      legal: activate.legal,
    });
  }

  async function invitation(email) {
    return await client.post(`/auth/invitation/${email}`);
  }

  async function login(credentials) {
    const { data } = await client.post("/auth/login", credentials);
    return {
      access: data.access_token,
      refresh: data.refresh_token,
    };
  }

  async function logout() {
    return await client.post("/auth/logout");
  }

  async function refresh(token) {
    const { data } = await client.post("/auth/token-refresh", {
      refresh_token: token,
    });
    return {
      access: data.access_token,
      refresh: data.refresh_token,
    };
  }

  return {
    activation,
    invitation,
    login,
    logout,
    refresh,
  };
}
