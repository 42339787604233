import { Role } from "../models/Role";
import { storeInstance } from "../store";
import { REFRESH_ACCESS_TOKEN } from "../store/modules/auth/auth.actions.type";
import { GET_PROFILE } from "../store/modules/auth/auth.getters.type";

export async function guardPanelRouter(to, from, next) {
  try {
    await storeInstance.dispatch(REFRESH_ACCESS_TOKEN);
    const profile = storeInstance.getters[GET_PROFILE];
    if (profile?.roles.includes(Role.admin)) {
      next();
      return;
    }
  } catch {
    next({ name: "login", params: to.params });
    return;
  }
  next({ name: "login", params: to.params });
}
