import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

import { storageTransformer } from "./service/transformer/storageTransformer";

dayjs.extend(dayjsPluginUTC);

export const TOAST_LIFE = 3 * 1000;

export const AVAILABLE_LOCALES = [
  { language: "English", code: "en" },
  { language: "Spanish", code: "es" },
];
export const DEFAULT_LOCALE = AVAILABLE_LOCALES[0].code;
export const MAX_EXPIRE_DATE_IN_YEARS = 5;
export const TRIAL_PROCESSING_TIME = parseInt(
  process.env.VUE_APP_TRIAL_PROCESSING_TIME
);
export const TRIAL_PROCESSING_TIME_IN_MIN = TRIAL_PROCESSING_TIME / 60;
export const TRIAL_STORAGE = storageTransformer.gigaToBytes(
  process.env.VUE_APP_TRIAL_STORAGE
);
export const TRIAL_STORAGE_IN_GB = storageTransformer.byteToGiga(TRIAL_STORAGE);
export const TRIAL_EXPIRE_DATE = dayjs
  .utc()
  .add(process.env.VUE_APP_TRIAL_EXPIRE_DATE, "day")
  .hour(0)
  .minute(0)
  .second(0)
  .millisecond(0)
  .toDate();

export const IS_DEBUG = process.env.NODE_ENV !== "production";
export const API_BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const GENERIC_CODE_ERROR = 0;
export const GENERIC_MSG_ERROR = "error.api.unknown";
export const FORBIDDEN_MSG_ERROR = "error.api.forbidden_access";
