import Contact from "../models/Contact";
import Customer from "../models/Customer";
import Wage from "../models/Wage";
import { dateTransformer } from "./utils";

export default function (client) {
  async function register(customer) {
    await client.post("/customers", {
      id: customer.id,
      company: customer.company,
      contact_name: customer.contact.name,
      contact_surname: customer.contact.surname,
      contact_email: customer.contact.email,
      time: customer.wage.totalTime,
      size: customer.wage.totalStorage,
      expires: dateTransformer.toISOString(customer.expires),
      locale: customer.locale,
    });
  }

  async function customers() {
    const { data } = await client.get("/customers");
    return data.customers.map(
      (customer) =>
        new Customer(
          customer.id,
          customer.company,
          new Contact(
            customer.contact.name,
            customer.contact.surname,
            customer.contact.email
          ),
          new Wage(
            customer.wage.time.total,
            customer.wage.time.consumed,
            customer.wage.storage.total,
            customer.wage.storage.used
          ),
          customer.clients.length,
          dateTransformer.toDate(customer.created_at),
          dateTransformer.toDate(customer.wage.expires),
          customer.locale
        )
    );
  }

  return {
    register,
    customers,
  };
}
